import { IMicrosite } from "../app/models/microsite";

export const microsites: IMicrosite[] = [
  {
    name: "Logger",
    entrypoint: "/logger",
    host: "https://console.hhs.intouchstaging.com/ui/logger",
    displayName: "Log Service",
  },
  {
    name: "Monitoring",
    entrypoint: "/monitoring",
    host: "https://console.hhs.intouchstaging.com/ui/monitoring",
    displayName: "Monitoring",
  },
  {
    name: "Updater",
    entrypoint: "/updater",
    host: "https://console.hhs.intouchstaging.com/ui/updater",
    displayName: "Updater",
  },
  {
    name: "DeviceProvisioning",
    entrypoint: "/provisioning",
    host: "https://console.hhs.intouchstaging.com/ui/provisioning",
    displayName: "Device Provisioning",
  },
  {
    name: "AccessManagement",
    entrypoint: "/access",
    host: "https://console.hhs.intouchstaging.com/ui/access",
    displayName: "Access Management",
  },
  {
    name: "PASProvisioning",
    entrypoint: "/pas-provisioning",
    host: "https://console.hhs.intouchstaging.com/ui/pas-provisioning",
    displayName: "PAS Provisioning",
  },
  {
    name: "AuxUpdater",
    entrypoint: "/auxupdater",
    host: "https://console.hhs.intouchstaging.com/ui/auxupdater",
    displayName: "Aux Updater",
  },
];
